import { useTranslate } from '/machinery/I18n'

import { TagButton, TagButtonWhite } from '/features/buildingBlocks/TagButton'

import iconChevronRight from '/images/icons/chevron-right.raw.svg'

import styles from './Tags.css'

export function Tags({ tags, onActiveTagChange, contentIsCentered = false }) {
  const { __ } = useTranslate()

  return (
    <TagsBase
      className={styles.component}
      title={__(tags.length)`read-more-about-subjects`}
      {...{ tags, renderTag, onActiveTagChange, contentIsCentered }}
    />
  )

  function renderTag(tag, index) {
    return (
      <TagButton
        label={tag}
        buttonId={index}
        dataX='click-to-open-tag-related-articles'
        onClick={() => onActiveTagChange(tag)}
      />
    )
  }
}

export function TagsCanonical({ tags, onActiveTagChange, contentIsCentered = false }) {
  return (
    <TagsBase
      className={styles.componentCanonical}
      {...{ tags, renderTag, onActiveTagChange, contentIsCentered }}
    />
  )

  function renderTag(tag) {
    return (
      <TagButtonWhite
        label={tag}
        icon={iconChevronRight}
        dataX='click-to-open-tag-related-articles'
        onClick={() => onActiveTagChange(tag)}
      />
    )
  }
}

function TagsBase({ tags, renderTag, className, title = undefined, contentIsCentered = false }) {
  return (
    <div className={cx(styles.componentBase, className, contentIsCentered && styles.isCentered)}>
      {title && <p className={styles.tagsTitle}>{title}</p>}

      <ul className={styles.tagsList}>
        {tags?.map((tag, index) => (
          <li key={index}>{renderTag(tag, index)}</li>
        ))}
      </ul>
    </div>
  )
}
