import styles from './ContentContainer.css'

export function ContentContainerDefault({ children, layoutClassName = undefined }) {
  return (
    <ContentContainerBase
      className={cx(styles.componentDefault, layoutClassName)}
      {...{ children }}
    />
  )
}

export function ContentContainerCentered({ children, layoutClassName = undefined }) {
  return (
    <ContentContainerBase
      className={cx(styles.componentCentered, layoutClassName)}
      {...{ children }}
    />
  )
}

function ContentContainerBase({ children, className }) {
  return <div className={cx(styles.componentBase, className)}>{children}</div>
}
