import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { useSpring } from '@react-spring/web'

import { ContainerMd } from '/features/buildingBlocks/Container'
import { HeadingGroup as HeadingGroupBase, HeadingGroupSnackables } from '/features/buildingBlocks/HeadingGroup'
import { Icon } from '/features/buildingBlocks/Icon'
import { MetaTagWrapper, MetaTag, MetaTagButton } from '/features/article/buildingBlocks/MetaTag'
import { ArticleHeroMemberOffer, ArticleHeroSingleSnackable, ArticleHeroSnackables } from '/features/article/buildingBlocks/ArticleHero'

import styles from './ArticleIntro.css'

import arrowDown from '/images/icons/arrow-down.raw.svg'

export function ArticleIntroDefault({
  hero,
  rubric,
  tags,
  isNextArticleIntro = false,
  onActiveTagChange = undefined,
  tagsClickable = false,
  url = undefined,
  layoutClassName = undefined
}) {
  return (
    <ArticleIntroBase
      className={cx(styles.componentDefault, layoutClassName)}
      {...{
        hero,
        url,
        rubric,
        tags,
        onActiveTagChange,
        tagsClickable,
        isNextArticleIntro
      }}
    />
  )
}

export function ArticleIntroDefaultBlack({
  hero,
  rubric,
  isNextArticleIntro = false,
  onActiveTagChange = undefined,
  url = undefined,
  layoutClassName = undefined
}) {
  return (
    <ArticleIntroBase
      className={cx(styles.componentDefaultBlack, layoutClassName)}
      {...{ hero, url, rubric, onActiveTagChange, isNextArticleIntro }}
    />
  )
}

export function ArticleIntroGedicht({
  hero,
  rubric,
  isNextArticleIntro = false,
  url = undefined,
  layoutClassName = undefined
}) {
  return (
    <ArticleIntroBase
      className={cx(styles.componentGedicht, layoutClassName)}
      {...{ hero, url, rubric, isNextArticleIntro }}
    />
  )
}

export function ArticleIntroSingleSnackable({
  hero,
  rubric,
  isNextArticleIntro = false,
  layoutClassName = undefined
}) {
  const { title, image, caption } = hero

  return (
    <div className={cx(styles.componentSingleSnackable, layoutClassName)}>
      <div className={cx(styles.introWrapper, isNextArticleIntro && styles.isNextArticleIntro)}>
        <ArticleHeroSingleSnackable heroImage={image} {...{ title, rubric, caption }} />
      </div>
    </div>
  )
}

export function ArticleIntroSnackables({
  hero,
  rubric,
  articles,
  isNextArticleIntro = false,
  layoutClassName = undefined
}) {
  const { title } = hero

  return (
    <div className={cx(styles.componentSnackables, layoutClassName)}>
      <div className={cx(styles.introWrapper, isNextArticleIntro && styles.isNextArticleIntro)}>
        <ArticleHeroSnackables {...{ title, rubric, articles }} />
      </div>
    </div>
  )
}

export function ArticleIntroBordered({
  hero,
  tags,
  rubric,
  isNextArticleIntro = false,
  onActiveTagChange = undefined,
  style = undefined,
  layoutClassName = undefined
}) {
  return (
    <ArticleIntroBase
      HeadingComponent={ArticleIntroUppercaseHeading}
      className={cx(styles.componentBordered, layoutClassName)}
      tagsClickable
      {...{ hero, tags, rubric, style, isNextArticleIntro, onActiveTagChange }}
    />
  )
}

export function ArticleIntroMemberOffer({
  hero,
  rubric,
  articles,
  isNextArticleIntro = false,
  layoutClassName = undefined
}) {
  const { title } = hero

  return (
    <div className={cx(styles.componentMemberOffer, layoutClassName)}>
      <div className={cx(styles.introWrapper, isNextArticleIntro && styles.isNextArticleIntro)}>
        <ArticleHeroMemberOffer layoutClassName={styles.introMemberOfferLayout} {...{ title, rubric, articles }} />
      </div>
    </div>
  )
}

function ArticleIntroBase({
  hero,
  rubric,
  className,
  isNextArticleIntro,
  HeadingComponent = ArticleIntroHeading,
  tags = undefined,
  onActiveTagChange = undefined,
  tagsClickable = false,
  containerComponent = undefined,
  url = undefined
}) {
  const { scrollProgression, ref: trackedElementRef } = useIntroScroll()
  const { title } = hero

  return (
    <div ref={trackedElementRef} className={cx(styles.componentBase, className)}>
      <div className={cx(styles.introWrapper, isNextArticleIntro && styles.isNextArticleIntro)}>
        <HeadingComponent
          layoutClassName={styles.headingLayout}
          {...{ containerComponent, title, rubric, tags, onActiveTagChange, tagsClickable, scrollProgression, url }}
        />
      </div>
    </div>
  )
}

function useIntroScroll() {
  const [scrollProgression, setScrollProgression] = React.useState(0)

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.top() },
    end: { element: triggers.bottom(), scrollParent: triggers.bottom(-200) },
    onChange(progression) { setScrollProgression(progression) }
  })

  return { scrollProgression, ref }
}

function ArticleIntroHeading({
  containerComponent,
  title,
  rubric,
  scrollProgression,
  isNextArticleIntro = false,
  url = undefined,
  tags = undefined,
  tagsClickable = undefined,
  onActiveTagChange = undefined,
  layoutClassName = undefined
}) {
  return (
    <ArticleIntroHeadingBase
      className={layoutClassName}
      {...{
        containerComponent, title, rubric, scrollProgression,
        isNextArticleIntro, url, tags, tagsClickable, onActiveTagChange
      }}
    />
  )
}

function ArticleIntroUppercaseHeading({
  title,
  rubric,
  scrollProgression,
  containerComponent = undefined,
  isNextArticleIntro = false,
  url = undefined,
  tags = undefined,
  tagsClickable = undefined,
  onActiveTagChange = undefined,
  layoutClassName = undefined
}) {
  return (
    <ArticleIntroHeadingBase
      HeadingGroup={HeadingGroupSnackables}
      className={layoutClassName}
      {...{
        containerComponent, title, rubric, scrollProgression,
        isNextArticleIntro, url, tags, tagsClickable, onActiveTagChange
      }}
    />
  )
}

function ArticleIntroHeadingBase({
  title,
  rubric,
  scrollProgression,
  HeadingGroup = HeadingGroupBase,
  containerComponent = undefined,
  isNextArticleIntro = false,
  url = undefined,
  tags = undefined,
  tagsClickable = undefined,
  onActiveTagChange = undefined,
  className = undefined
}) {
  const Container = containerComponent || ContainerMd

  const [animation] = useSpring(getSpringConfig, [scrollProgression])

  return (
    <div className={cx(styles.componentHeadingBase, className)}>
      <Container>
        <div className={styles.headerLayoutWrapper}>
          <HeadingGroup
            h={1}
            subtitle={rubric}
            layoutClassName={styles.headingGroupLayout}
            {...{ title, animation }}
          />

          {tags && (
            <MetaTagWrapper animation={toCssVariable('opacity', animation.opacity)}>
              {tags.map((label, i) => {
                if (tagsClickable) return <MetaTagButton key={i} {...{ label, onActiveTagChange }} />
                else return <MetaTag key={i} {...{ label }} />
              })}
            </MetaTagWrapper>
          )}

          {url && <ArrowIcon layoutClassName={styles.arrowIconLayout} />}
        </div>
      </Container>
    </div>
  )

  /** @returns {import('@react-spring/web').UseSpringProps} */
  function getSpringConfig() {
    return {
      immediate: true,
      opacity: isNextArticleIntro ? null : 1 - (easeIn(scrollProgression) * 3)
    }
  }
}

function ArrowIcon({ layoutClassName = undefined }) {
  return (
    <div className={layoutClassName}>
      <Icon icon={arrowDown} layoutClassName={styles.arrowLayout} />
    </div>
  )
}

function easeIn(x) {
  return x * x
}

/**
 * We need to use a css variable to prevent Chrome from breaking 'backdrop-filter'
 * due to 'opacity' not being compatible whilst used simultaneously.
 *
 * @param {string} x variable name
 * @param {any} value variable value
 */
function toCssVariable(x, value) {
  return { [`--${x}`]: value }
}
