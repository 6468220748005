import { useKeenSlider as useKeenSliderHook } from 'keen-slider/react'

import { trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { stopPropagationEventHandlers } from '/machinery/stopPropagationEventHandlers'

import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ArticleCard } from '/features/article/buildingBlocks/ArticleCard'

import styles from './ArticleCardSlider.css'

export function ArticleCardSlider({ articles, title = undefined, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      {title && <HeadingSm h={2}>{title}</HeadingSm>}
      {Boolean(articles?.length) && <Slider {...{ articles }} />}
    </div>
  )
}

function Slider({ articles }) {
  const sliderRef = useKeenSlider({ slides: articles, number: articles?.length })
  const sizes = `var(--slide-width)`

  return (
    <div
      ref={sliderRef}
      className={cx(styles.componentSlider, 'keen-slider')}
      {...stopPropagationEventHandlers()}
    >
      {articles.map((item, i) => (
        <div key={i} className={styles.slide}>
          <ArticleCard sizes layoutClassName={styles.articleCardLayout} {...{ sizes, item }} />
        </div>
      ))}
    </div>
  )
}

function useKeenSlider({ slides, number }) {
  const [sliderRef] = useKeenSliderHook({
    disabled: !slides?.length,
    mode: 'free-snap',
    range: { align: false },
    slides: { origin: 0, number, perView: 'auto' },
    selector: `.${styles.slide}`
  }, [keenSliderTrackingPlugin])

  return sliderRef
}

function keenSliderTrackingPlugin() {
  return slider => {
    slider.on('slideChanged', trackDragInteraction('slide_changed'))
    slider.on('dragStarted', trackDragInteraction('drag_started'))
    slider.on('dragEnded', trackDragInteraction('drag_ended'))
  }

  function trackDragInteraction(action) {
    trackInteraction({
      action,
      title: 'interaction',
      type: 'drag'
    })
  }
}
