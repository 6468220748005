import { useLanguage, useTranslate } from '/machinery/I18n'
import { animated } from '@react-spring/web'
import { routeMap } from '/routeMap'

import { ContentContainerDefault } from '/features/article/buildingBlocks/ContentContainer'
import { ArticleCardSlider } from '/features/buildingBlocks/ArticleCardSlider'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import { ContainerLg } from '/features/buildingBlocks/Container'

import chrevronRight from '/images/icons/chevron-right.raw.svg'

import styles from './ArticleReadMore.css'

export function ArticleReadMore({
  tags = [],
  renderTags,
  relatedArticles,
  animation = undefined,
  layoutClassName = undefined,
}) {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <animated.div style={animation} className={cx(styles.component, layoutClassName)}>
      <ContentContainerDefault layoutClassName={styles.contentContainerLayout}>
        <div className={styles.background}>
          <ContainerLg>
            <div className={styles.container}>
              <h4 className={styles.readMoreTitle}>
                {__(tags?.length)`read-more-about-subjects`}
              </h4>

              {renderTags && renderTags()}

              <ArticleCardSlider articles={relatedArticles} layoutClassName={styles.articleCardSliderLayout} />

              <ButtonLinkPrimary
                dataX='link-to-articles'
                href={routeMap.app.articles.overview({ language })}
                label='bekijk alle artikelen'
                icon={chrevronRight}
              />
            </div>
          </ContainerLg>
        </div>
      </ContentContainerDefault>
    </animated.div>
  )
}
