import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { animated } from '@react-spring/web'

import { routeMap } from '/routeMap'
import { useArticlesWithReadState } from '/domain/hooks/useArticlesWithReadState'
import { useScrollProgressionProgressbar, useScrollProgressionScalingCard } from '/machinery/useScrollProgressionEvents'
import { mergeRefs } from '/machinery/mergeRefs'
import { useTranslate } from '/machinery/I18n'
import { useParams } from '/machinery/Params'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { useItemUrl } from '/features/regionArticles/useItemUrl'
import { useScrollbarWidth } from '/machinery/useScrollbarWidth'
import { useEvent } from '/machinery/useEvent'
import { useURLGeneratorContext } from '/machinery/URLGenerator'

import { Crediter } from '/features/buildingBlocks/Crediter'
import { useDrawerGestures } from '/features/regionArticles/useDrawerGestures'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { ReadProgression } from '/features/article/buildingBlocks/ReadProgression'
import { Menu, MenuCanonical } from '/features/pageOnly/menu/Menu'
import { ArticleHeroBordered, ArticleHeroDefault } from '/features/article/buildingBlocks/ArticleHero'
import { ArticleIntroBordered, ArticleIntroDefault, ArticleIntroGedicht, ArticleIntroMemberOffer, ArticleIntroSingleSnackable, ArticleIntroSnackables } from '/features/article/buildingBlocks/ArticleIntro'
import { AudioPlayerArticlePage } from '/features/gedicht/buildingBlocks/AudioPlayer'
import { ContentContainerDefault, ContentContainerCentered } from '/features/article/buildingBlocks/ContentContainer'
import { Tags, TagsCanonical } from '/features/article/buildingBlocks/Tags'
import { TagModal } from '/features/article/buildingBlocks/TagModal'
import { Credits } from '/features/article/buildingBlocks/Credits'
import { NextArticle } from '/features/article/buildingBlocks/NextArticle'
import { DeckToc } from '/features/regionArticles/DeckToc'
import { extractArticles } from '/features/regionArticles/extractArticles'
import { findCover } from '/features/regionArticles/findCover'
import { ContentWoodwing, ContentItemWoodwing, ContentWoodwingKader, ContentSanity } from '/features/Content.js'
import { ImageCover } from '/features/buildingBlocks/Image'
import { HeadingGroup } from '/features/buildingBlocks/HeadingGroup'
import { ButtonLinkSecondary } from '/features/buildingBlocks/Button'
import { DrawerArticleLink } from '/features/regionArticles/deckToc/DrawerArticleLink'
import { SearchDrawer, SearchCanonical } from '/features/regionArticles/SearchDrawer'
import { ArticleButtons } from '/features/article/buildingBlocks/ArticleButtons'
import { ArticleReadMore } from '/features/article/buildingBlocks/ArticleReadMore'
import { ArticleRelatedIssue } from '/features/article/buildingBlocks/ArticleRelatedIssue'
import { Icon } from '/features/buildingBlocks/Icon'
import { FooterPage } from '/features/pageOnly/Footer'

import styles from './ArticlePage.css'

import iconArrowUp from '/images/icons/arrow-up.raw.svg'
import chrevronRight from '/images/icons/chevron-right.raw.svg'

export function ArticlePageDefaultWithoutContext({ doc, cover, relatedArticles }) {
  const renderers = getArticlePageDefaulRenderersWithoutContext({ doc, cover, relatedArticles })
  return <ArticlePageWithoutContext {...{ doc, cover, relatedArticles }} {...renderers} />
}

export function ArticlePageDefault({ doc, feedData, issues, specials }) {
  const renderers = getArticlePageDefaulRenderers({ doc })
  return <ArticlePage {...{ doc, feedData, issues, specials }} {...renderers} />
}

export function ArticlePageKaliberDefaultWithoutContext({ doc }) {
  const renderers = getArticlePageKaliberDefaultRenderers({ doc })
  return <ArticlePageWithoutContext {...{ doc }} {...renderers} />
}

export function ArticlePageKaliberDefault({ doc, feedData, issues, specials }) {
  const renderers = getArticlePageKaliberDefaultRenderers({ doc })
  return <ArticlePage {...{ doc, feedData, issues, specials }} {...renderers} />
}

export function ArticlePageSingleSnackableWithoutContext({ doc }) {
  const renderers = getArticlePageSingleSnackableRenderers({ doc })
  return <ArticlePageWithoutContext {...{ doc }} {...renderers} />
}

export function ArticlePageSingleSnackable({ doc, feedData, issues, specials }) {
  const renderers = getArticlePageSingleSnackableRenderers({ doc })
  return <ArticlePage {...{ doc, feedData, issues, specials }} {...renderers} />
}

export function ArticlePageGedichtWithoutContext({ doc }) {
  const renderers = getArticlePageGedichtRenderers({ doc })
  return <ArticlePageWithoutContext {...{ doc }} {...renderers} />
}

export function ArticlePageGedicht({ doc, feedData, issues, specials }) {
  const renderers = getArticlePageGedichtRenderers({ doc })
  return <ArticlePage {...{ doc, feedData, issues, specials }} {...renderers} />
}

export function ArticlePageSnackablesWithoutContext({ doc }) {
  const renderers = getArticlePageSnackablesRenderers({ doc })
  return <ArticlePageWithoutContext {...{ doc }} {...renderers} />
}

export function ArticlePageBorderedWithoutContext({ doc }) {
  const renderers = getArticlePageBorderedRenderers({ doc })
  return <ArticlePageWithoutContext {...{ doc }} {...renderers} />
}

export function ArticlePageSnackables({ doc, feedData, issues, specials }) {
  const renderers = getArticlePageSnackablesRenderers({ doc })
  return <ArticlePage {...{ doc, feedData, issues, specials }} {...renderers} />
}

export function ArticlePageBordered({ doc, feedData, issues }, specials) {
  const renderers = getArticlePageBorderedRenderers({ doc })
  return <ArticlePage {...{ doc, feedData, issues, specials }} {...renderers} />

}
export function ArticlePageMemberOffer({ doc, feedData, issues, specials }) {
  const renderers = getArticlePageMemberOfferRenderers({ doc })
  return <ArticlePage {...{ doc, feedData, issues, specials }} {...renderers} />
}

export function ArticlePageMemberOfferWithoutContext({ doc }) {
  const renderers = getArticlePageMemberOfferRenderers({ doc })
  return <ArticlePageWithoutContext {...{ doc }} {...renderers} />
}

function TagsArticleCanonicalPage({ tags, activeTag, onActiveTagChange }) {
  return (
    <>
      <TagsCanonical {...{ tags, onActiveTagChange }} />
      <TagModal isCanonical {...{ activeTag, tags, onActiveTagChange }} />
    </>
  )
}

function ArticlePageTags({ tags, activeTag, onActiveTagChange, isCanonical = false }) {
  return (
    <>
      <Tags {...{ tags, onActiveTagChange }} />
      <TagModal {...{ activeTag, tags, onActiveTagChange, isCanonical }} />
    </>
  )
}


function getArticlePageDefaulRenderers({ doc }) {
  return getDefaultRenderers({ doc })
}

function getArticlePageDefaulRenderersWithoutContext({ doc, cover, relatedArticles }) {
  const { content, metadata: { tags, credits } } = doc
  const queryClient = new QueryClient()

  return {
    ...getDefaultRenderers({ doc }),

    renderContentComponent: ({ sectionRef, animation, isCanonical, activeTag, onActiveTagChange, layoutClassName }) =>
      <QueryClientProvider client={queryClient}>
        <ArticlePageContentDefault
          contentItems={content.items}
          {...{ sectionRef, animation, isCanonical, tags, doc, cover, relatedArticles, activeTag, onActiveTagChange, credits, layoutClassName }}
        />
      </QueryClientProvider>,
  }
}

function getArticlePageKaliberDefaultRenderers({ doc }) {
  const { content, metadata: { rubric, tags, credits } } = doc
  const { hero } = content
  const queryClient = new QueryClient()

  return {
    ...getDefaultRenderers({ doc }),

    renderButtonsComponent: ({ layoutClassName }) =>
      <ArticleButtons {...{ hero, layoutClassName }} />,

    renderIntroComponent: ({ onActiveTagChange, layoutClassName }) => (hero.title || hero.column) &&
      <ArticleIntroDefault
        tagsClickable
        rubric={rubric.name}
        {...{ hero, tags, onActiveTagChange, layoutClassName }}
      />,

    renderContentComponent: ({ sectionRef, animation, isCanonical, activeTag, onActiveTagChange, layoutClassName }) =>
      <QueryClientProvider client={queryClient}>
        <ArticlePageContentKaliberResearch contentItems={content.items} {...{ sectionRef, animation, isCanonical, tags, activeTag, onActiveTagChange, credits, layoutClassName }} />
      </QueryClientProvider>,
  }
}

function getArticlePageSingleSnackableRenderers({ doc }) {
  const { content, metadata: { tags, rubric, credits } } = doc
  const { hero } = content
  const queryClient = new QueryClient()

  return {
    renderButtonsComponent: ({ layoutClassName }) =>
      <ArticleButtons {...{ hero, layoutClassName }} />,

    renderIntroComponent: ({ layoutClassName }) => (hero.title || hero.column) &&
      <ArticleIntroSingleSnackable
        rubric={rubric.name}
        {...{ hero, layoutClassName }}
      />,

    renderContentComponent: ({ sectionRef, animation, isCanonical, activeTag, onActiveTagChange, layoutClassName }) =>
      <QueryClientProvider client={queryClient}>
        <ArticlePageContentDefault contentItems={content.items} {...{ sectionRef, animation, isCanonical, tags, activeTag, onActiveTagChange, credits, layoutClassName }} />
      </QueryClientProvider>
  }
}

function getArticlePageSnackablesRenderers({ doc }) {
  const { content: { hero, articles }, metadata: { rubric } } = doc

  return {
    renderButtonsComponent: ({ layoutClassName }) =>
      <ArticleButtons {...{ hero, layoutClassName }} />,

    renderIntroComponent: ({ layoutClassName }) => (hero.title || rubric.name) &&
      <ArticleIntroSnackables
        rubric={rubric.name}
        {...{ hero, articles, layoutClassName }}
      />,

    renderContentComponent: ({ layoutClassName, animation, sectionRef }) =>
      <ArticlePageContentSnackables {...{ doc, layoutClassName, animation, sectionRef }} />
  }
}

function getArticlePageBorderedRenderers({ doc }) {
  const { content: { hero }, metadata: { tags, rubric } } = doc

  return {
    ...getDefaultRenderers({ doc }),

    renderHeroComponent: ({ layoutClassName }) => hero.image &&
      <ArticleHeroBordered {...{ hero, layoutClassName }} />,

    renderIntroComponent: ({ layoutClassName, animation, onActiveTagChange }) => (hero.title || rubric.name) &&
      <ArticleIntroBordered
        rubric={rubric.name}
        style={animation}
        {...{ tags, hero, layoutClassName, onActiveTagChange }}
      />
  }
}

function getArticlePageMemberOfferRenderers({ doc }) {
  const { content: { hero, articles }, metadata: { rubric } } = doc

  return {
    renderButtonsComponent: ({ layoutClassName }) =>
      <ArticleButtons {...{ hero, layoutClassName }} />,

    renderIntroComponent: ({ layoutClassName }) => (hero.title || rubric.name) &&
      <ArticleIntroMemberOffer
        rubric={rubric.name}
        {...{ hero, articles, layoutClassName }}
      />,

    renderContentComponent: ({ layoutClassName, animation, sectionRef }) =>
      <ArticlePageContentSnackables {...{ doc, layoutClassName, animation, sectionRef }} />
  }
}

function PreviousArticleButton({ previous, hideBar, layoutClassName }) {
  const { __ } = useTranslate()
  const previousUrl = useItemUrl(previous)

  return (
    <animated.a
      href={previousUrl}
      data-x='link-to-previous-article'
      className={cx(styles.componentPreviousArticleButton, hideBar && styles.needsBackground, layoutClassName)}
    >
      <Icon icon={iconArrowUp} layoutClassName={styles.iconLayout} />
      <span>{__`previous-article`}</span>
    </animated.a>
  )
}

function getArticlePageGedichtRenderers({ doc }) {
  const { content, metadata: { rubric } } = doc
  const { hero } = content

  return {
    ...getDefaultRenderers({ doc }),

    renderIntroComponent: ({ layoutClassName }) => (hero.title || hero.column) &&
      <ArticleIntroGedicht
        rubric={rubric.name}
        {...{ hero, layoutClassName }}
      />,

    renderContentComponent: ({ layoutClassName, animation, sectionRef }) =>
      <ArticlePageContentGedicht {...{ doc, layoutClassName, animation, sectionRef }} />
  }
}

function getDefaultRenderers({ doc }) {
  const { content, metadata: { tags, rubric, credits } } = doc
  const { hero } = content
  const queryClient = new QueryClient()

  return {
    renderButtonsComponent: ({ layoutClassName }) =>
      rubric.slug !== 'kleingeld'
        ? <ArticleButtons {...{ hero, layoutClassName }} />
        : null,

    renderHeroComponent: ({ layoutClassName }) => hero.image &&
      <ArticleHeroDefault {...{ hero, layoutClassName }} />,

    renderIntroComponent: ({ onActiveTagChange, layoutClassName }) => (hero.title || hero.column) &&
      <ArticleIntroDefault
        tagsClickable
        rubric={rubric.name}
        {...{ hero, tags, onActiveTagChange, layoutClassName }}
      />,

    renderContentComponent: ({ sectionRef, animation, isCanonical, activeTag, onActiveTagChange, layoutClassName }) =>
      <QueryClientProvider client={queryClient}>
        <ArticlePageContentDefault
          contentItems={content.items}
          {...{ sectionRef, animation, isCanonical, tags, activeTag, onActiveTagChange, credits, layoutClassName }}
        />
      </QueryClientProvider>,
  }
}

function ArticlePage({
  doc,
  feedData,
  issues,
  specials,
  renderButtonsComponent,
  renderIntroComponent,
  renderContentComponent,
  renderHeroComponent = undefined
}) {
  const { feedItems, region, title } = feedData
  const renderers = { renderButtonsComponent, renderHeroComponent, renderIntroComponent, renderContentComponent }
  const renderDeckItemComponent = ({ item, url }, idx) => <DrawerArticleLink key={idx} {...{ item, url }} />
  const { articles, handleArticleRead } = useArticlesWithReadState({ articles: extractArticles(feedItems) })

  const cover = findCover(feedItems)
  const [currentArticleIndex, articleCount] = [findArticleIndex(doc, articles) + 1, articles.length]

  const { bind, searchDrawer, tocDrawer } = useDrawerGestures({ dragGesturesEnabled: true })
  const scrollbarWidth = useScrollbarWidth()

  const [readProgressionTargetRef, setReadProgressionTargetRef] = React.useState(null)

  const { trackedRefProgressbar, width, hideBar, reachedEnd } = useScrollProgressionProgressbar({
    onEndReached: () => handleArticleRead(doc.id),
    scrollParentEnd: 0.9,
  })

  useRefCallbackWhenMounted({
    ref: trackedRefProgressbar,
    onRefMounted: x => setReadProgressionTargetRef(() => x),
  })

  const { determineDeckFeedLink, determineItemSlug } = useURLGeneratorContext()
  const params = useParams()
  const backLink = `${determineDeckFeedLink(params)}#${determineItemSlug(doc)}`

  const articleIndex = findArticleIndex(doc, feedItems)
  const previousLink = articleIndex > 1 ? feedItems[articleIndex - 1] : null

  return (
    <div className={cx(styles.component, hideBar && styles.withBarHidden)} style={{ '--scrollbar-width': `${scrollbarWidth}px` }}>
      <Menu
        finishedReading={currentArticleIndex === articleCount}
        layoutClassName={styles.menuLayout}
        {...{ tocDrawer, searchDrawer, currentArticleIndex, articleCount }}
      />

      <DeckToc
        coverImage={cover.content.hero.image}
        onClose={tocDrawer.handleClose}
        isOpen={tocDrawer.isOpen}
        layoutClassName={styles.deckTocLayout}
        {...{ region, title, articles, issues, specials, renderDeckItemComponent }}
      />

      <SearchDrawer
        onClose={searchDrawer.handleClose}
        isOpen={searchDrawer.isOpen}
        coverImage={cover.content.hero.image}
        layoutClassName={styles.searchLayout}
      />

      {previousLink && (
        <PreviousArticleButton
          previous={previousLink}
          layoutClassName={styles.previousLayout}
          {...{ hideBar }}
        />
      )}

      <ReadProgression
        readTime={`${doc.metadata.readTime} min`}
        layoutClassName={styles.readProgressionLayout}
        {...{ backLink, width, hideBar, reachedEnd }}
      />

      <div {...bind()}>
        <ArticlePageBodyWithNextArticle {...renderers} {...{ doc, feedData, readProgressionTargetRef, issues, specials }} />
      </div>
    </div>
  )
}

function ArticlePageWithoutContext({
  doc,
  renderButtonsComponent,
  renderIntroComponent,
  renderContentComponent,
  renderHeroComponent = undefined
}) {
  const { language } = useParams()
  const backLink = routeMap.app.home({ language })
  const [readProgressionTargetRef, setReadProgressionTargetRef] = React.useState(null)
  const { handleArticleRead } = useArticlesWithReadState()

  const { bind, searchDrawer } = useDrawerGestures({ dragGesturesEnabled: true })

  const { trackedRefProgressbar, width, hideBar, reachedEnd } = useScrollProgressionProgressbar({
    onEndReached: () => handleArticleRead(doc.id),
    scrollParentEnd: 1,
  })

  useRefCallbackWhenMounted({
    ref: trackedRefProgressbar,
    onRefMounted: x => setReadProgressionTargetRef(() => x),
  })

  return (
    <div className={cx(styles.componentWithoutContext)}>
      <MenuCanonical layoutClassName={styles.menuLayout} {...{  searchDrawer }} />

      <SearchCanonical
        onClose={searchDrawer.handleClose}
        isOpen={searchDrawer.isOpen}
        layoutClassName={styles.searchLayout}
        coverImage={doc?.content?.hero?.image}
      />

      <ReadProgression
        readTime={`${doc.metadata.readTime} min`}
        layoutClassName={styles.readProgressionLayout}
        {...{ backLink, width, hideBar, reachedEnd }}
      />

      <div {...bind()}>
        <ArticlePageBody
          isCanonical
          {...{
            doc,
            renderButtonsComponent,
            renderHeroComponent,
            renderIntroComponent,
            renderContentComponent,
            readProgressionTargetRef
          }}
        />
      </div>
    </div>
  )
}

function ArticlePageBodyWithNextArticle({
  doc,
  renderButtonsComponent,
  renderHeroComponent,
  renderIntroComponent,
  renderContentComponent,
  issues,
  specials,
  feedData,
  readProgressionTargetRef,
}) {
  const { content: { hero } } = doc
  const { feedItems } = feedData

  const next = feedItems[findArticleIndex(doc, feedItems) + 1]

  return (
    <ArticlePageBodyBase
      // isCanonical
      className={styles.componentBodyWithNextArticle}
      {...{
        hero,
        renderButtonsComponent,
        renderHeroComponent,
        renderIntroComponent,
        renderContentComponent,
        readProgressionTargetRef
      }}
    >
      {Boolean(next) && <NextArticle doc={next} layoutClassName={styles.nextArticleLayout} {...{ issues, specials }} />}
    </ArticlePageBodyBase>
  )
}

function ArticlePageBody({
  doc,
  renderHeroComponent,
  renderIntroComponent,
  renderContentComponent,
  renderButtonsComponent,
  isCanonical,
  readProgressionTargetRef,
  children = undefined
}) {
  const { content: { hero } } = doc

  return (
    <ArticlePageBodyBase
      className={styles.componentBody}
      {...{
        hero,
        isCanonical,
        renderHeroComponent,
        renderIntroComponent,
        renderContentComponent,
        renderButtonsComponent,
        readProgressionTargetRef
      }}
    >
      {children}
    </ArticlePageBodyBase>
  )
}

function ArticlePageBodyBase({
  renderHeroComponent,
  renderIntroComponent,
  renderContentComponent,
  renderButtonsComponent,
  isCanonical = false,
  readProgressionTargetRef,
  className,
  children = undefined
}) {
  const { scrollProgressionScalingRef, scale } = useScrollProgressionScalingCard()
  const [activeTag, setActiveTag] = React.useState(null)

  return (
    <div className={cx(styles.componentBodyBase, className)}>
      {renderButtonsComponent && (
        <div className={cx(styles.articleButtonsContainer, styles.articleButtonsContainerLayout)}>
          {renderButtonsComponent({ layoutClassName: styles.articleButtonsLayout })}
        </div>
      )}

      {/* // This wrapper is needed because of a layout issue. The container is given a height of 0
          // and in turn it will give its children a height of the viewport, check css comment for details */}
      {renderHeroComponent && (
        <div className={cx(styles.articleHeroContainer, styles.articleHeroContainerLayout)}>
          {renderHeroComponent({ layoutClassName: styles.articleHeroLayout })}
        </div>
      )}

      {renderIntroComponent({
        onActiveTagChange: handleActiveTagChange,
        layoutClassName: styles.articleIntroLayout
      })}

      {renderContentComponent && (
        <>
          <CardBorder layoutClassName={styles.cardBorderLayout} {...{ scale }} />

          {renderContentComponent({
            isCanonical,
            activeTag,
            onActiveTagChange: handleActiveTagChange,
            animation: { scaleX: scale },
            sectionRef: mergeRefs(readProgressionTargetRef, scrollProgressionScalingRef),
            layoutClassName: styles.scalingCardLayout,
          })}
        </>
      )}

      {children}
    </div>
  )

  function handleActiveTagChange(tag) {
    setActiveTag(tag)

    if (!tag) return

    pushToDataLayer({
      event: 'tag_popup_show',
      metadata: {
        filter: {
          tag
        }
      }
    })

  }
}

function CardBorder({ scale, layoutClassName }) {
  return (
    <animated.div
      className={cx(styles.componentCardBorder, layoutClassName)}
      // Overwrite transform to prevent react-spring from doing translate(0)
      style={{ scale, transform: 'translateY(1px)' }}
    />
  )
}

export function ArticlePageContent({
  sectionRef,
  animation,
  children,
  credits,
  tags = [],
  isCanonical = false,
  contentIsCentered = false,
  doc = undefined,
  cover = undefined,
  relatedArticles = undefined,
  onActiveTagChange = undefined,
  activeTag = undefined,
  layoutClassName = undefined
}) {
  const ContentContainerComponent = contentIsCentered
    ? ContentContainerCentered
    : ContentContainerDefault

  return (
    <animated.section
      ref={sectionRef}
      style={animation}
      data-x='page-content'
      className={cx(styles.componentContent, layoutClassName)}
    >
      <ContentContainerComponent layoutClassName={styles.contentContainerLayout}>
        {children}

        {Boolean(credits) && (
          <ContainerMd>
            <Credits credits={Object.entries(credits).map(([position, name], i) =>
              <Crediter key={`credit-${i}`} {...{ position, name }} />
            )} />
          </ContainerMd>
        )}

        {Boolean(isCanonical && doc?.issue) && (
          <ArticleRelatedIssue issue={doc.issue} issueTitle={doc.issueTitle} {...{ cover }} />
        )}

        {isCanonical && Boolean(tags?.length) && (
          <ArticleReadMore
            renderTags={() => <TagsArticleCanonicalPage {...{ tags, activeTag, onActiveTagChange }} />}
            {...{ tags, relatedArticles, activeTag, isCanonical, onActiveTagChange }}
          />
        )}

        {!isCanonical && Boolean(tags?.length) && onActiveTagChange &&  (
          <ContainerMd>
            <ArticlePageTags {...{ tags, activeTag, onActiveTagChange, isCanonical }} />
          </ContainerMd>
        )}

        {isCanonical && <FooterPage layoutClassName={styles.footerLayout} />}
      </ContentContainerComponent>
    </animated.section>
  )
}

function ArticlePageContentKaliberResearch({
  sectionRef,
  animation,
  isCanonical,
  contentItems,
  credits,
  activeTag = undefined,
  onActiveTagChange = undefined,
  tags = [],
  contentIsCentered = false,
  layoutClassName = undefined
}) {
  return (
    <ArticlePageContent {...{ sectionRef, animation, isCanonical, credits, tags, activeTag, onActiveTagChange, contentIsCentered, layoutClassName }}>
      <ContentSanity styleId='page' layoutStyleId='page' {...{ contentItems }} />
    </ArticlePageContent>
  )
}

function ArticlePageContentDefault({
  sectionRef,
  animation,
  isCanonical,
  contentItems,
  credits,
  tags = [],
  doc = undefined,
  cover = undefined,
  relatedArticles = undefined,
  activeTag = undefined,
  onActiveTagChange = undefined,
  contentIsCentered = false,
  layoutClassName = undefined
}) {
  const props = { sectionRef, animation, isCanonical, activeTag, contentIsCentered, layoutClassName }
  const data = { doc, credits, tags, cover, relatedArticles }
  const events = { onActiveTagChange }

  return (
    <ArticlePageContent {...props} {...data} {...events}>
      <ContentWoodwing styleId='page' layoutStyleId='page' {...{ contentItems }} />
    </ArticlePageContent>
  )
}

function ArticlePageContentGedicht({ doc, sectionRef, animation, layoutClassName = undefined }) {
  const { content, metadata } = doc
  const { credits } = metadata || {}
  const { poem, author, description, position, audio } = content

  return (
    <ArticlePageContent contentIsCentered {...{ sectionRef, animation, credits, layoutClassName }}>
      <div className={styles.componentContentGedicht}>
        {audio && (// TODO: Erik - Move this check to normalization
          <div className={styles.audioLayout}>
            <AudioPlayerArticlePage src={audio.src} />
          </div>
        )}

        <ContentItemWoodwing styleId='page' item={poem} />

        <div className={cx(styles.nameAndPosition, styles.nameAndPositionLayout)}>
          {author && <ContentItemWoodwing item={author} {...{ layoutClassName }} />} {/* TODO: Erik - Move this check to normalization */}
          {position && <ContentItemWoodwing item={position} {...{ layoutClassName }} />} {/* TODO: Erik - Move this check to normalization */}
        </div>

        {description && (
          <ContainerMd layoutClassName={styles.kaderLayout}>
            <ContentWoodwingKader contentItems={description} styleId='page' />
          </ContainerMd>
        )}
      </div>
    </ArticlePageContent>
  )
}

function ArticlePageContentSnackables({ doc, animation, sectionRef, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const { slug, content: { articles }, metadata: { tags, credits } } = doc || {}

  return (
    <ArticlePageContent {...{ animation, sectionRef, tags, credits, layoutClassName }}>
      <SnackableArticles {...{ articles }} />

      {slug === 'ledenaanbiedingen' && (
        <ContainerMd>
          <div className={styles.buttonContainer}>
            <ButtonLinkSecondary
              href='https://www.rabobank.nl/leden/ledenaanbiedingen'
              dataX='link-to-ledenaanbiedingen'
              label={__`check-all-member-offers`}
              icon={chrevronRight}
              layoutClassName={styles.buttonLayout}
            />
          </div>
        </ContainerMd>
      )}
    </ArticlePageContent>
  )
}

function SnackableArticles({ articles }) {
  return articles.map((article, i) => (
    <ContainerMd key={i}>
      <SnackableArticle {...{ article }} />
    </ContainerMd>
  ))
}

function SnackableArticle({ article }) {
  return (
    <div className={styles.componentSnackableArticle}>
      <div>
        <ImageCover image={article.content.hero.image} aspectRatio={2 / 3} />
      </div>
      <div className={styles.articleContentContainer}>
        <div className={styles.contentTitleContainer}>
          <HeadingGroup h={2} title={article.content.hero.title} />
        </div>
        <ContentWoodwing layoutStyleId='snackable' styleId='snackable' contentItems={article.content.items} />
      </div>
    </div>
  )
}

function findArticleIndex(article, articles) {
  const { slug } = article.metadata.rubric

  return articles.findIndex(x => x.metadata.rubric.slug === slug)
}

function useRefCallbackWhenMounted({ onRefMounted, ref }) {
  const callbackEvent = useEvent(onRefMounted)

  React.useEffect(
    () => {
      callbackEvent(ref)
      return () => { callbackEvent(null) }
    },
    [ref, callbackEvent]
  )
}
