import { useQuery } from '@tanstack/react-query'
import { useQueryString } from '@kaliber/sanity-routing/queryString'

import { useLanguage, useTranslate } from '/machinery/I18n'
import { useClientConfig } from '/machinery/ClientConfig'
import { useSearchContext } from '/machinery/SearchContext'
import { ensureIntegerLargeOrEqual } from '/machinery/ensure'
import { getCanonicalArticleUrl } from '/features/regionArticles/deckToc/useArticleInfo'

import { getEsSearchResult, getEsSearchResultWithinAndNotWithinRegion } from '/features/search/requests'
import { ModalMd } from '/features/buildingBlocks/Modal'
import { PopupContentDefault } from '/features/regionArticles/cards/popups/content/PopupContentDefault'
import { RelatedArticle } from '/features/article/buildingBlocks/RelatedArticle'
import { ButtonTertiary } from '/features/buildingBlocks/Button'

import styles from './TagModal.css'

export function TagModal({ tags, activeTag, onActiveTagChange, isCanonical }) {
  return (
    <ModalMd
      active={Boolean(activeTag)}
      onClose={handlePopupClose}
      content={
        <PopupContentDefault renderCard={
          isCanonical
            ? renderModalCanonical
            : renderModal
        } />
      }
    />
  )

  function renderModal({ layoutClassName }) {
    return <TagModalContent {...{ activeTag, tags, layoutClassName }} />
  }

  function renderModalCanonical({ layoutClassName }) {
    return <TagModalContentCanonical {...{ activeTag, tags, layoutClassName }} />
  }

  function handlePopupClose() {
    onActiveTagChange(null)
  }
}

function TagModalContent({ tags, activeTag, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const config = useClientConfig()

  const [{ size: rawSize }, setQueryString] = useQueryString()
  const size = ensureIntegerLargeOrEqual(rawSize, config.pagination.resultsPerPage)

  const data = useArticles({ tag: activeTag, language, size })
  const { withinRegion, notWithinRegion } = data
  const index = tags.findIndex(x => x === activeTag)

  return (
    <TagModalContentBase {...{ activeTag, index, layoutClassName }}>
      {withinRegion?.articles?.map((item, i) => (
        <RelatedArticle key={i} url={getCanonicalArticleUrl(item)} {...{ item }} />
      ))}
      {Boolean(notWithinRegion?.articles?.length) && (
        <div className={styles.articlesContainer}>
          <h3 className={styles.differentRegionsHeading}>{__`from-different-regions`}</h3>
          {notWithinRegion?.articles?.map((item, i) => (
            <RelatedArticle key={i} url={getCanonicalArticleUrl(item)} {...{ item }} />
          ))}
          {size <= notWithinRegion.total && <ButtonTertiary onClick={handleClick} label={__`load-more`} dataX='load-more-artices' layoutClassName={styles.buttonLayout} />}
        </div>
      )}
    </TagModalContentBase>
  )

  function handleClick() {
    setQueryString(x => ({ ...x, size: size + config.pagination.resultsPerPage }))
  }
}

function TagModalContentCanonical({ activeTag, tags, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const config = useClientConfig()
  const [{ size: rawSize }, setQueryString] = useQueryString()

  const size = ensureIntegerLargeOrEqual(rawSize, config.pagination.resultsPerPage)
  const { articles, total } = useArticlesCanonical({ tag: activeTag, language, size })

  const index = tags.findIndex(x => x === activeTag)

  return (
    <TagModalContentBase {...{ activeTag, index, layoutClassName }}>
      <div className={styles.componentContentCanonical}>
        {articles?.map((item, i) => (
          <RelatedArticle key={i} url={getCanonicalArticleUrl(item)} {...{ item }} />
        ))}
        {size <= total && <ButtonTertiary onClick={handleClick} label={__`load-more`} dataX='load-more-artices' layoutClassName={styles.buttonLayout} />}
      </div>
    </TagModalContentBase>
  )

  function handleClick() {
    setQueryString(x => ({ ...x, size: size + 10 }))
  }
}

function TagModalContentBase({ activeTag, index, layoutClassName, children }) {
  return (
    <div className={cx(styles.componentContentBase, layoutClassName)}>
      <TagModalHeading {...{ activeTag, index }} />
      {children}
    </div>
  )
}

function TagModalHeading({ activeTag, index, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <h2 className={cx(styles.componentHeading, layoutClassName)}>
      {__`more-articles-about`} <TagHeading tag={activeTag} {...{ index }} />
    </h2>
  )
}

function TagHeading({ tag, index }) {
  const buttonVariant = index % 3

  switch (buttonVariant) {
    case 0: return <TagModalHeadingGreen {...{ tag }} />
    case 1: return <TagModalHeadingCream {...{ tag }} />
    case 2: return <TagModalHeadingCyan {...{ tag }} />
    default: return <TagModalHeadingGreen {...{ tag }} />
  }
}

function TagModalHeadingGreen({ tag }) {
  return <span className={styles.componentHeadingGreen}>{tag}</span>
}

function TagModalHeadingCream({ tag }) {
  return <span className={styles.componentHeadingCream}>{tag}</span>
}

function TagModalHeadingCyan({ tag }) {
  return <span className={styles.componentHeadingCyan}>{tag}</span>
}

function useArticles({ tag, language, size }) {
  const { bank, region } = useSearchContext()
  const placeholderDataRef = React.useRef({ withinRegion: { articles: [], total: 0 }, notWithinRegion: { articles: [], total: 0 } })

  const { data } = useQuery({
    queryKey: ['tagModal', language, tag, bank, region, size],
    queryFn: async () => {
      const data = await getEsSearchResultWithinAndNotWithinRegion({ bank, region, tags: [tag], language, size })
      placeholderDataRef.current = data

      return data
    },
    placeholderData: placeholderDataRef.current,
    enabled: Boolean(tag)
  })

  return data
}

function useArticlesCanonical({ tag, language, size }) {
  const placeholderDataRef = React.useRef({ articles: [], total: 0 })

  const { data } = useQuery({
    queryKey: ['tagModalCanonical', language, tag, size],
    queryFn: async () => {
      const data = await getEsSearchResult({ tags: [tag], language, size })
      placeholderDataRef.current = data
      return data
    },
    placeholderData: placeholderDataRef.current,
    enabled: Boolean(tag),
  })

  return data
}
