import { useLanguage, useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'

import { ContentContainerDefault } from '/features/article/buildingBlocks/ContentContainer'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { IssueCard } from '/features/buildingBlocks/IssueCard'

import logo from '/images/branding/rabo-en-co-logo.svg'

import styles from './ArticleRelatedIssue.css'

export function ArticleRelatedIssue({ cover, issue, issueTitle }) {
  const { __ } = useTranslate()

  return (
    <ContentContainerDefault>
      <ContainerMd>
        <div className={styles.component}>
          <h4 className={styles.title}>{__`read-whole-edition`}</h4>
          <Columns {...{ cover, issue, issueTitle }} />
        </div>
      </ContainerMd>
    </ContentContainerDefault>
  )
}

function Columns({ cover, issue, issueTitle }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <div className={styles.componentColumns}>
      {issue && (
        <IssueCard
          href={routeMap.app.issue({ language, issue })}
          image={cover?.content?.hero?.image}
          title={__`read-issue`}
          layoutClassName={styles.issueCardLayout}
          {...{ logo }}
        />
      )}

      {issueTitle && (
        <div className={styles.text}>
          <strong className={styles.issueTextTitle}>
            {issueTitle}
          </strong>

          <p className={styles.paragraph}>
            {__({ issue: issueTitle.toLowerCase() })`article-published-season-x-year-x`}
          </p>
        </div>
      )}
    </div>
  )
}
