import { routeMap } from '/routeMap'
import { useLanguage } from '/machinery/I18n'

import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'
import { HeadingXs } from '/features/buildingBlocks/Heading'

import styles from './ArticleCard.css'

import iconChevron from '/images/icons/chevron-right.raw.svg'

export function ArticleCard({ item, sizes, layoutClassName = undefined }) {
  const { id, content, metadata, issueTitle } = item
  const { readTime, rubric, tags } = metadata
  const { hero } = content

  return (
    <li className={cx(styles.component, layoutClassName)}>
      <div className={styles.imageContainer}>
        <MetaTags layoutClassName={styles.metaTagsLayout} {...{ issueTitle, readTime }} />
        <ImageCover image={hero.image} aspectRatio={3 / 2} {...{ sizes }} />
      </div>

      <div className={styles.textContainer}>
        <CardTags rubric={rubric.name} {...{ tags }} />
        <CardAnchor title={hero.title} layoutClassName={styles.anchorLayout} {...{ id }} />
      </div>
    </li>
  )
}

function CardAnchor({ id, title, layoutClassName }) {
  const language = useLanguage()

  const href = routeMap.app.articles.single({ language, idOrIds: id })

  return (
    <a data-x='link-to-article' className={cx(styles.componentCardAnchor, layoutClassName)} {...{ href }}>
      <HeadingXs h={3} layoutClassName={styles.titleLayout}>{title}</HeadingXs>

      <span className={styles.iconContainer}>
        <Icon icon={iconChevron} layoutClassName={styles.iconLayout} />
      </span>
    </a>
  )
}

function MetaTags({ issueTitle, readTime, layoutClassName }) {
  return (
    <ul className={cx(styles.componentMetaTags, layoutClassName)}>
      {readTime && <MetaTag tag={`${readTime} min`} />}
      {issueTitle && <MetaTag tag={issueTitle} />}
    </ul>
  )
}

function MetaTag({ tag }) {
  return <li className={styles.componentMetaTag}>{tag}</li>
}

function CardTags({ rubric, tags }) {
  return (
    <ul className={styles.componentCardTags}>
      <CardTagRubric {...{ rubric }} />
      {tags?.slice(0, 2)?.map((tag, i) => <CardTag key={i} {...{ tag }} />)}
    </ul>
  )
}

function CardTagRubric({ rubric }) {
  return <CardTagBase className={styles.componentCardTagRubric} tag={rubric} />
}

function CardTag({ tag }) {
  return <CardTagBase className={styles.componentCardTag} {...{ tag }} />
}

function CardTagBase({ tag, className }) {
  return <li className={cx(styles.componentCardTagBase, className)}>{tag}</li>
}
